var regexp = /(\b(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)/ig,
    root_host = window.location.protocol + "//" + window.location.host,
    current_link = null,
    prev_link = null,
    form_files = null,
    file_available = false,
    controller_url = null,
    body_iterval = null,
    controller_this = null;

var trumbowyg_options = {
                          btns: [
                            ['undo', 'redo'], // Only supported in Blink browsers
                            ['strong', 'em', 'del'],
                            ['link'],
                            ['insertImage'],
                            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                            ['unorderedList', 'orderedList'],
                            ['removeformat']
                          ],
                          autogrow: true,
                          removeformatPasted: true,
                          urlProtocol: true,
                          minimalLinks: true,
                          svgPath: "/assets/ui/icons-2af4ee240de260f43f147c21ebc37880a1d0bf8e5c855e1defc9b8e6293a1d7a.svg"
                        };
                        

export function initPostingTools(form_type, _this) {

  controller_this = _this;

  $(".posting_tool #post_body").on('input', function () {
    showPreview();
  });

  function showPreview() {
    if (($(".posting_tool #post_body") !== undefined) && ($(".posting_tool #post_body").val() !== undefined)) {

      var text = $(".posting_tool #post_body").val();

      var link = text.match(regexp);
      if (link != null) {
        if (link[0] != current_link && link[0] != prev_link) {
          $(".posting_tool .previews").show();
          $(".posting_tool .loading_wrap").show();
          
          current_link = link[0];
          
          $.ajax({
            data: { 
                    link: link[0]
                  },
            dataType: 'json',
            type: 'get',
            url: root_host + "/post/preview",
            success : function(data, textStatus, jqXHR) {   
                        console.log("preview: ", data)
                        console.log(data.title)
                        if ((data.videos !== undefined) || (data.type == "photo") || (data.images !== undefined)) {
                          $(".posting_tool .loading_wrap").hide();
                          //current_link = link[0];
                          $(".posting_tool #post_url").attr("value", current_link);
          
                          if (data.videos.length > 0 ) $(".posting_tool .preview img").attr("src", data.images[0].src).show();
                          if (data.type == "photo") $(".posting_tool .preview img").attr("src", data.url).show();
                          if (data.images.length > 0) {
                            $(".posting_tool .preview").addClass('link');
                            if (data.images !== []) {
                              $(".posting_tool .preview img").attr("src", data.images[0].src).show();
                            } else {
                              $(".posting_tool .preview img").attr("src", $(".posting_tool .link_bg").attr('src')).show();
                            }
                            //
                            $(".posting_tool .preview .link_title").html(data.title);
                            $(".posting_tool .preview .link_descr").html(data.description);
                          }
                        } else if (data.type == "pdf") {
                          $(".posting_tool .loading_wrap").hide();
                          //current_link = link[0];
                          $('.posting_tool .previews img').attr('src', $('.posting_tool .pdf_bg').attr('src')).show();

                          $(".posting_tool #post_url").attr("value", current_link);
                        } else if (data.status == "processing") {
                          //alert("processing");
                          current_link = null;
                          setTimeout(showPreview, 2000);
                        }
                      },
            complete: function () { },
            error: function (xhr, ajaxOptions, thrownError) {
              //alert("ERROR");
              current_link = null;
              setTimeout(showPreview, 3000);
              //maybe show 'refresh icon'
              //$(".posting_tool .loading_wrap").hide();
            }
          });
        }
      }
    }
  }

  $(".posting_tool .preview .clean").on("click", function (event) {
    cleanPostPreview();
  });

  $(".posting_tool #post_button").on("click", function (event) {
    if (file_available == true) { 
      $(".posting_tool .posting.hidden").removeClass('hidden');
      $(this).hide();

      var formData = new FormData();
      $.each($('.posting_tool form').serializeArray(), function(_, item) {
        formData.append(item.name, item.value);
      });

      if (form_files.length > 1) {

        for (var i=0; i < form_files.length; i++) {
          formData.append("post[photos][image"+i+"]", form_files[i]);
        }
      }
      else {
        formData.append("post[photo]", form_files[0]);
      }
      
      controller_url = "/posts";

      $.ajax({
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,  
        url: root_host + controller_url,
        complete : function(data, textStatus, jqXHR) {
                    controller_this.postProcessing(data.responseJSON);
                  }
      });


    } else if ($(".posting_tool #post_body").val() != "" || $(".posting_tool #files").val() != "" || $(".posting_tool #pdf_files").val() != "" || $(".posting_tool #post_url").val() != "" ) { 
      $(".posting_tool .posting").show();
      $(this).hide();
      $(".posting_tool form #submit_button").click();
    }
    form_files = null;
    file_available = false;
  });

  $(".posting_tool input#files").on("change", function (e) {
    beforeFileSelectHandler(e);
  });

  $(".posting_tool input#pdf_files").on("change", function (e) {
    beforePDFFileSelectHandler(e);
  });

  function cleanPostPreview() {
    $(".posting_tool #files, .posting_tool #pdf_files, .posting_tool #post_url").attr("value", "");
    $(".posting_tool #files").val('');
    $(".posting_tool #pdf_files").val('');
    file_available = false;
    prev_link = current_link;
    current_link = null;
    $(".posting_tool .previews").hide();
    $('.posting_tool .previews .preview .multi_images').html("");
    $(".posting_tool .preview img").attr("src", "").hide();
    $(".posting_tool .preview p").html("");
    $(".posting_tool .preview.link").removeClass('link');
  }

  // file drag hover
  function FileDragHover(e) {
    e.stopPropagation();
    e.preventDefault();
    e.target.className = (e.type == "dragover" ? "hover" : "");
  }

  // file selection
  function FileSelectHandler(e) {
    // cancel event and hover styling
    FileDragHover(e);
    // fetch FileList object
    var dt = e.dataTransfer || (e.originalEvent && e.originalEvent.dataTransfer);
    form_files = e.target.files || (dt && dt.files);
    // process all File objects
    if (form_files && form_files[0]) {
      $('.posting_tool .previews').show();

      if (form_files.length > 1) {
        $(".posting_tool .preview").addClass('multi');
        let files_length = form_files.length
        
        if (files_length > 10)
          files_length = 10;

        for (var i=0; i < files_length; i++) {
          if ((form_files[i].size / 1024 / 1024) < 5){
            var reader = new FileReader();
            reader.onload = function (e) {
              $('.posting_tool .previews .preview .multi_images').append("<div class='img' style='background-image:url(" + e.target.result + ")'></div>");
            }
            reader.readAsDataURL(form_files[i]);
          }
        }
      } else {
        var reader = new FileReader();
        reader.onload = function (e) {
          $('.posting_tool .previews img').attr('src', e.target.result).show();
        }
        reader.readAsDataURL(form_files[0]);

      }
      file_available = true;
      $(".posting_tool .loading_wrap").hide();
      $('.posting_tool #post_body').focus();
    }
    var rotateImage = function(image) {
      switch(image.Orientation){
        case 8 || 7:
          $('.posting_tool .preview img').rotate(-90);
        break;
        case 3 || 4:
          $('.posting_tool .preview img').rotate(180);
        break;
        case 6 || 5:
          $('.posting_tool .preview img').rotate(90);
        break;
        default:
          $('.posting_tool .preview img').rotate(0);
      }
    };
    //$(".posting_tool input#files").fileExif(rotateImage);
  }

  function PDFFileSelectHandler(e) {
    FileDragHover(e);
    var dt = e.dataTransfer || (e.originalEvent && e.originalEvent.dataTransfer);
    form_files = e.target.files || (dt && dt.files);

    if (form_files && form_files[0]) {
      $('.posting_tool .previews').show();
      
      var reader = new FileReader();
      reader.onload = function (e) {
        $('.posting_tool .previews img').attr('src', $('.posting_tool .pdf_bg').attr('src')).show();
        //alert(form_files[0].name + "(" + toKb(form_files[0].size) + "Kb)");
      }
      reader.readAsDataURL(form_files[0]);

      $('.posting_tool #post_body').focus();
    }
  }

  function beforeFileSelectHandler(e) {
    var dt = e.dataTransfer || (e.originalEvent && e.originalEvent.dataTransfer);

    form_files = e.target.files || (dt && dt.files);

    if (typeof form_files !== 'undefined') {
      FileSelectHandler(e);
    } else {
      var file = $("#files").val();
      if ( ["JPEG","JPG","PNG","GIF"].indexOf(file.substr(file.lastIndexOf('.') +1).toUpperCase()) < 0) {
        alert("Please use an image file JPEG,JPG,PNG,GIF.");
        e.preventDefault();
      }
    }

  }

  function beforePDFFileSelectHandler(e) {

    var dt = e.dataTransfer || (e.originalEvent && e.originalEvent.dataTransfer);

    form_files = e.target.files || (dt && dt.files);

    if (typeof form_files !== 'undefined') {
      PDFFileSelectHandler(e); 
    } else {
      var file = $("#pdf_files").val();
      if ( ["PDF"].indexOf(file.substr(file.lastIndexOf('.') +1).toUpperCase()) < 0) {
        alert("Please use PDF files only.");
        e.preventDefault();
      }
    }

  }

}

