import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "submit", "output", "addr", "city", "country", "lat", "lon", "countryshort", "input", "map", "name"]

  connect() {
    let _this = this;
    window.loadingGoogleApi = window.loadingGoogleApi || $.getScript("https://maps.googleapis.com/maps/api/js?v=3.0&libraries=places&language=en&key=AIzaSyCd76X3yBtCPPbMLOzt3CgVYtni7fldyqM&callback=dispatchMapsEvent");
    window.loadingGoogleApi.done(function () {initSearch(_this.inputTarget, _this)});


    if (typeof (google) != "undefined"){
      this.map()
    }
  }


  inputHandler(event) {
    this.submitTarget.disabled = true;
    if (event.key == "Enter") { event.preventDefault() }
  }

  map() {
    if(this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(
          this.latTarget.value,
          this.lonTarget.value
        ),
        zoom: 9
      })
    }
    return this._map
  }

  marker() {
    if (this._marker == undefined) {
      this._marker = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0,0)
      })

      this._marker.setVisible(true)
    }
    return this._marker
  }

}



function setResults(place, targets) {
  let result = "";

  let formatted_address = "";
  let street = "";
  let street_number = "";
  let city = "";
  let country = "";
  let country_short = "";
  let lat = "";
  let lon = "";
  let name = "";



  place.address_components.forEach(function(item) {
    if(item.types.includes("locality"))
      city = item.long_name

    if(item.types.includes("administrative_area_level_3"))
      city = item.short_name

    if(item.types.includes("country")) {
      country = item.long_name
      country_short = item.short_name
    }

    if(item.types.includes("route"))
      street = item.long_name

    if(item.types.includes("street_number"))
      street_number = item.long_name


  });

  formatted_address = place.formatted_address;
  lat = place.geometry.location.lat();
  lon = place.geometry.location.lng();

  result = `Address ${street} ${street_number}, ${city}, ${country} - ${lat}:${lon} - [${formatted_address}]`;


  //Init form
  targets.addrTarget.value = street + " " + street_number;
  targets.cityTarget.value = city;
  targets.countryTarget.value = country;
  targets.latTarget.value = lat;
  targets.lonTarget.value = lon;
  targets.countryshortTarget.value = country_short;
  targets.nameTarget.value = place.name;

  return result;
}

function initSearch(input, targets) {
  let searchBox = new google.maps.places.SearchBox(input, { types: ['(continent)']});

  //Saved address
  targets.map();
  targets.marker().setPosition({lat: parseFloat(targets.latTarget.value), lng: parseFloat(targets.lonTarget.value)})
  targets.marker().setVisible(true)
  ///

  searchBox.addListener('places_changed', function() {
    
    var places = searchBox.getPlaces();

    var lon, lat, addr, city, country;

    addr = city = country = "";
    
    if (places.length == 0) {
      return;
    }

    places.forEach(function(place) {
      targets.submitTarget.disabled = false;
      setResults(place, targets)

      //MAP
      targets.map().fitBounds(place.geometry.viewport)
      targets.map().setCenter(place.geometry.location)

      targets.marker().setPosition(place.geometry.location)
      targets.marker().setVisible(true)
      //END

    });
  });
}
