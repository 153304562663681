import { Controller } from "stimulus";
import $ from 'jquery';
import { initPostingTools } from "../packs/posting.js.erb";
import {initMagnificPopup} from '../packs/initMagnificPopup'

export default class extends Controller {
  static targets = ["editor"]

  connect () {
    let _this = this;

    initPostingTools("innovation", this);

    if (this.data.get("processing") == "true") {
      setTimeout(function() {_this.getPostBy(_this.data.get("postid"))}, 5000);
    }
  }

  onCreateSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.postProcessing(data);
  }

  postProcessing(data){
    let _this = this;

    if (data !== undefined) {
      if (data.status == "ok") {
        data.ids.forEach(function(item) {
          _this.getPostBy(item);
        });
      }

    }

    $('.mfp-close').click();
    setTimeout(function() {initMagnificPopup();}, 400);
  }



  onUpdateSuccess(event) {
    let post_id = this.data.get("postid");
    if (post_id > 0) {
      this.getPostBy(post_id);
      $('.mfp-close').click();
      setTimeout(function() {initMagnificPopup();}, 400);
    }
  }

  onDismiss(event) {
    let post_id = this.data.get("postid");
    if (post_id > 0) {
      $("#post_" + post_id).removeClass("animate scale-up");
      $("#post_" + post_id).addClass("animate scale-down");
      setTimeout(function() {$("#post_" + post_id).remove();}, 400);
    }
  }

  getPostBy(post_id) {
    fetch("/posts/" + post_id + "?card=true")
    .then(data => {return data.text();})
    .then(html => {
      if (html.length > 0) {
        if ($("#post_" + post_id).length > 0) {
          $("#post_" + post_id).replaceWith(html);
        } else {
          $("#posts_grid").prepend(html);
        }
        
      }
    });
  }

  showPreview() {

    if (($(".posting_tool #post_body") !== undefined) && ($(".posting_tool #post_body").val() !== undefined)) {

      var text = $(".posting_tool #post_body").val();

      var link = text.match(regexp);
      if (link != null) {
        if (link[0] != current_link && link[0] != prev_link) {
          $(".posting_tool .previews").show();
          $(".posting_tool .loading_wrap").show();
          
          current_link = link[0];
          
          $.ajax({
            data: { 
                    link: link[0]
                  },
            dataType: 'json',
            type: 'get',
            url: root_host + "/post/preview",
            success : function(data, textStatus, jqXHR) {   
                        console.log(data);
                        console.log(">>>>TEST>>>")
                        if (true) {
                          $(".posting_tool .loading_wrap").hide();
                          //current_link = link[0];
                          $(".posting_tool #post_url").attr("value", current_link);
          
                          if (data.type == "video") $(".posting_tool .preview img").attr("src", data.images[0].url).show();
                          if (data.type == "photo") $(".posting_tool .preview img").attr("src", data.url).show();
                          if (data.type == "rich") {
                            $(".posting_tool .preview").addClass('link');
                            if (data.images !== []) {
                              $(".posting_tool .preview img").attr("src", data.images[0].url).show();
                            } else {
                              $(".posting_tool .preview img").attr("src", $(".posting_tool .link_bg").attr('src')).show();
                            }
                            //
                            $(".posting_tool .preview .link_title").html(data.title);
                            $(".posting_tool .preview .link_descr").html(data.description);
                          }
                        } else if (data.type == "pdf") {
                          $(".posting_tool .loading_wrap").hide();
                          //current_link = link[0];
                          $('.posting_tool .previews img').attr('src', $('.posting_tool .pdf_bg').attr('src')).show();

                          $(".posting_tool #post_url").attr("value", current_link);
                        } else if (data.status == "processing") {
                          //alert("processing");
                          current_link = null;
                          setTimeout(showPreview, 2000);
                        }
                      },
            complete: function () {
              console.log("complete") 
            },
            error: function (xhr, ajaxOptions, thrownError) {
              //alert("ERROR");
              current_link = null;
              setTimeout(showPreview, 3000);
              //maybe show 'refresh icon'
              //$(".posting_tool .loading_wrap").hide();
            }
          });
        }
      }
    }
  }


}